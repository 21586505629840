
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full container height */
    margin-top: 2rem;
  }
  
  .spinner-line {
    width: 5px;
    height: 50px;
    margin: 0 5px;
    background-color: #007bff; /* Primary blue color */
    animation: expand 1.2s ease-in-out infinite;
  }
  
  .spinner-line:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .spinner-line:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .spinner-line:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  @keyframes expand {
    0%, 100% {
      transform: scaleY(0.5);
      opacity: 0.6;
    }
    50% {
      transform: scaleY(1.5);
      opacity: 1;
    }
  }
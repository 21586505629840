body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.a{
	text-decoration: none;
	

}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-in {
  animation: slide-in 0.5s ease-out;
}
/* Sidebar container */
.sidebar {
  position: fixed; /* Fixes the sidebar's position */
  top: 0;
  left: 0;
  height: 100%; /* Full height */
  width: 250px; /* Set the width of the sidebar */
  background-color: #111; /* Background color */
  padding-top: 20px; /* Top padding */
  display: flex;
  flex-direction: column; /* Arrange children vertically */
}

/* Sidebar links */
.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: block;
}

/* Change color on hover */
.sidebar a:hover {
  background-color: #575757;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50; /* Higher than the sidebar */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@base utilities;
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



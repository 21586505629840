.App {
  text-align: center;
  overflow-x: hidden
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;  /* IE and Edge */
}



li:not(:last-child) {
  margin-bottom: 20px;
}



.mediaFormContainer{
  padding: 1rem 2rem;
}

.mediaFormContainer .header{
  font-size: 1.5rem;
  font-weight: 600;
}

.uploadContainer{
  margin: 2rem 0;
}

.uploadIcon{
  font-size: 3rem;
  color: #A3A3A3;
}

.uploadText{
  color: #A3A3A3;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.image-display-container{
  padding: 1rem 2rem;
}

.image-display-container .header{
  font-size: 1.5rem;
  font-weight: 600;
}

.masonry-item{
  position: relative;
}

.masonry-item .delete-btn{
  position: absolute;
  top: 0;
  right: 0;
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.image-container img {
  width: 400px;
  height: 300px;
  object-fit: cover;
  border-style: #282c34 solid 1px;
  border-radius: 10px;
  margin: 4px;
  padding: 3px;
  
}

.images-collection {
 display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  
  
}

#popup-root .popup-content {
  padding: 50px;
  display: inline;
}

#popup-root .popup-content input {
  color: black;
}

#popup-root .popup-content .success-msg {
  color: rgb(53, 172, 53);
}

.logo {
  position: absolute;
  top: 24px; /* Adjust this to align with the hamburger menu */
  right: 10px; /* This will place the icon on the right for mobile views */
}

/* Media query for larger screens/desktops */
@media (min-width: 768px) { /* 768px is a typical breakpoint for tablets. Adjust as needed */
  .logo {
      left: 10px; /* This will place the icon on the left for desktop views */
      right: auto; 
  }
}
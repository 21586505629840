$yellow:#04c1f5;
$blue:#fdfdfd;

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $color;
		-moz-box-shadow:inset $top $left $blur $color;
		-o-box-shadow:inset $top $left $blur $color;
		box-shadow:inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		-o-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin clip-path($clip) {
	-webkit-clip-path: $clip;
	-moz-clip-path: $clip;
	-ms-clip-path: $clip;
	clip-path: $clip;
}

@mixin transition($transition) {
	-moz-transition:    all $transition ease;
	-o-transition:      all $transition ease;
	-webkit-transition: all $transition ease;
	-ms-transition: 	all $transition ease;
	transition:         all $transition ease;
}

.paddding {
	padding-left: 0!important;
	padding-right: 0!important;
}



// Header

.navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
	color: #000000!important;
	padding-top: 12px!important;
	padding-bottom: 15px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	margin: 0 20px!important;
	@include transition(0.5s);
	background: $blue!important;
	@include clip-path(polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0));
	;
}
.navbar-light .navbar-nav .nav-link {
	color: #222!important;
	padding-top: 12px!important;
	padding-bottom: 15px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	margin: 0 20px!important;
	@include transition(0.5s);
	position: relative!important;
	@include clip-path(polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0));
	border-radius: 5px;

	&:focus, &:hover {
		background: $blue!important;
		color: #080101!important;
		padding-top: 12px!important;
		padding-bottom: 20px!important;
		padding-left: 20px!important;
		padding-right: 20px!important;
		margin: 0 20px;
		position: relative;
		border-radius: 7px; // Adjust this value for more or less rounding
	}
}	
.bg{
	background: #fffbfb
}
.bg-faded {
    background-color: #010003;
  }

  .bgCard{
	background: #fff;
  }
  .bgRecent{
	background: #ffffff;
  }

.mobile_logo_width {
	display: none;
}

.navbar-nav {
 flex-direction: row !important;
 cursor: pointer;   
}

// form
.heading {
    font-size: 20px;
	color: #424040;
    font-weight: 600;
}

.input-text-box {
    @include border-radius(0!important);
	font-size: 15px!important;
	height: 45px!important;
	letter-spacing: 1px!important;  
}

.form-control:focus {
    box-shadow: none !important;
    border-color: $yellow !important;
}

.btn-sign-in {
    background: #c43421!important;
    @include border-radius(0!important);
	color: #fff!important;
	padding: 10px 50px!important;
    border-radius: 5px !important;
}

.btn-sign-up {
    background: #4287f5!important;
    @include border-radius(0!important);
	color: #fff!important;
	padding: 10px 50px!important;
    border-radius: 5px !important;
}

.blog-form input:focus {
    border-color: $blue !important;
}

.description-box {
    @include border-radius(0!important);
	height: 150px;
	resize: none;
	padding-top: 20px!important;
	letter-spacing: 1px!important; 
}

.btn-add {
    background: #28b7ff !important;
	@include border-radius(0!important);
	color: #fff!important;
	padding: 10px 50px!important;
}

.msgBtn {
	background: #b8c5f5 !important;
	border-top-left-radius: 1cm;
	color :  #000000 !important;
	padding: 10px 10px!important;
	
	opacity: 0.4;

}
.chat__body{ 

	flex: column;
	
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}	
	margin-top: auto;
	gap: 10px;
	justify-content: space-between;

	.span{
		background-color: #f89faa;
		padding: 5px;
		border-radius: 5px;
		color: #000000 !important;
	}
	}
	
.chat__footer{
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: #fff;
	border-top: 1px solid #ddd;
	
	color: #000;
	}

.chat__footer > form > input{
	flex: 1;
	padding: 10px;
	border-radius: 30px;
	border: 1px solid #ddd;
	outline: none;

	}
	.chat__name {
		font-size: 12px;
		color: #777;
		margin-bottom: 5px;
		
		background-color: #f89faa;
		padding: 5px;
		border-radius: 5px;
		color: #000000 !important;
		
		margin-top: 1rem;


		
		
	}

	.chat__message {
		background-color: #eef0f5;
		padding: 10px;
		border-radius: 20px;
		color: #000000 !important;
		margin-bottom: 10px;
		margin-top: 1rem;
		
		
		

	}

	.chat__timestamp {
		font-size: 9px;
		color: #777;
		margin-left: 10px;
		margin-top: 2rem;
		
	}
	
	


.break{
	border-bottom: 2px solid #0a0909;
	margin : 10px 2px;
	margin-bottom: 2px;


}

.ticker {

	width: 300px; // or whatever width you want
	height: 200px; // or whatever height you want
	object-fit: cover;
	
	overflow: hidden;
	background-color: #050404;
	justify-items: center;
	
	margin: 0 auto;
	padding: 10px;

	
	
	.a{
		color : #ffffff !important;
		text-decoration: none;

	}

	.span{
		color : #000000 !important;
		
	}

	

}



.links{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	padding: 10px;
	height: 60;
	width: 100%;
	text-decoration: none;
	color: #000000;
	background-color: #f7f6f6;
	margin-bottom: 5px;
	max-width: fit-content;
	font-size: x-small;

	
	.a{
		color : #000000 !important;
		text-decoration: none;
		
		

	}

	.span{
		color : #000000 !important;
		
	}

	

}
.h6 {
	color : #000000 !important;
	text-decoration: underline;
	font-size: x-small;
}

.news-container {
	width: 100%;
	max-width: 100%;
	padding: 1em; // Add some padding for breathing room
  
	.news-header {
	  font-weight: bold;
	  text-align: center; // Center align the header
	  margin-bottom: 1em; // Space between the header and the carousel
	}
  
	.news-item {
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
  
	  a {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-decoration: none;
		color: inherit; // Keeps the color of the text consistent
  
		.news-image {
		  width: 80%; // Adjust as per your preference
		  border-radius: 8px; // Give a slight curve to the image corners
		  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); // Adds a shadow to the image
		  margin-bottom: 1em; // Space between image and text
		}
  
		.news-text {
		  text-align: center;
		  width: 80%; // You can adjust this as needed
		  font-size: 1.1em; // Makes the font size slightly bigger
		}
	  }
	}
  }
  
  // Media query for larger screens, it can be adjusted
  @media (min-width: 992px) {
	.news-container {
	  padding: 2em; // Increase padding for larger screens
	}
  }
.stock {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	padding: 10px;
	height: 60;
	width: 100%;
	
	.a{
		color : #000000 !important;
		text-decoration: none;

	}

	.span{
		color : #000000 !important;
		
	}

}

.bgBlue{
	background-color: #f2f3f4;
}


.react-tag-input {
    height: 45px !important;
}

.react-tag-input:focus {
    border-color: $yellow !important;   
}

.catg-dropdown {
    width: 100%;
    border-radius: 4px;
    height: 45px;
    color: #806a78;
  }
  
  .catg-dropdown:focus {
      outline: none !important;
      border-color: $yellow;
  }
  
  .trending {
      float: left;
      color: #806a78;
      font-size: 18px;
      margin: auto;
  }

//   Blog Section

.blog-heading {
	font-size: 24px;
	color: rgb(216, 221, 230);
    border-bottom: 5px solid rgb(244, 243, 243);
	font-weight: 200;
	font-family: "Poppins";
	text-decoration: none;
	align-items: center;
	
	

	
}

.featblog{
	position: relative;
	top: 50%;
   left: 5%
   
}

.blogs-img {
    height: 200px;
    border-radius: 5px; /* Replaced mixin */
    overflow: hidden;
    position: relative;
    top: 0;
    box-shadow: 0 2px 3px 1px rgba(0,0,0,0); /* Replaced mixin */
    transition: all 0.5s; /* Replaced mixin */
}

.blogCard {
    height: 1rem;
	border-radius: 3px;
	position: center;
	padding: 10px 15px;

	left: 10px;
	margin: 10px 0;
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0));
	@include transition(all 0.5s);


	&:hover {
		@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0.3));
	}

	max-width: fit-content;

	

}

.blogs-img > img {
    width: 100%; /* Take the full width of the container */
    height: 100%; /* Take the full height of the container */
    object-fit: cover; /* Cover the container without squishing */
}

.hover-blogs-img:hover > .blogs-img {
     
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0.3));
	top: -5px;


}

.title {
    font-size: 17px;
	color: rgb(31, 30, 30)!important;
	font-weight: 800;
    font-family: "Poppins";
    text-decoration: none;
	display: block;
}


.category {
    display: inline-block;
    position: relative;
    background-color: #4287f5;
    padding: 4px 8px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    text-transform: none;
    line-height: 16px;
    font-weight: 700;
    border-radius: 4px;
}

.fa-trash {
	color: #c43421 !important;
}

.fa-edit {
	color: rgb(11, 15, 16) !important;
}

.meta-info {
    font-size: 15px;
	color: #222;
	display: block;
    font-family: "Poppins";
    text-decoration: none;
}

.blog-single-content .meta-info {
	margin-top: 8px;
	border-bottom: 1px solid #777;
	margin-bottom: 15px;
}

.author {
  display: inline-block;
  font-weight: 600;
  font-size: 15px !important;	
}

.short-description {
    font-size: 14px;
	color: #777;
}

.btn-read {
    background: #f1f8f9 !important;
	@include border-radius(0!important);
	color: rgb(8, 8, 8)!important;
	padding: 5px 10px!important;
	float: left;
	margin-top: 10px;
	font-size: 14px;
}

// Detail

.blog-title-box {
    width: 38.2%;
	z-index: 555;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

    .single & {
		position: relative;
		height: 700px;
		width: 100%;
	}

    .overlay {
        position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		background: rgba(0,0,0,.4);
    }

    .blog-title {
        z-index: 12;
		position: absolute;
		bottom: 0;
		padding: 30px;

        .single & {
			width: 100%;
			margin: 0 auto;
			text-align: center;
		}

        h2 {
            color: white;
			font-weight: 300;
            .single & {
				font-size: 72px;
			}
        }

        > span {
            display: block;
			color: rgba(255, 255, 255, 0.5);
			text-transform: uppercase;
			font-size: 15px;
			letter-spacing: .05em;
			margin-bottom: 10px;
        }
    }
    
}

.blog-single-content {
    z-index: 999;
	background: white;

	p, ul li {
		font-size: 1.05rem;
	}
}

// Home
.spinner {
	width: 3rem;
	height: 3rem;
}

.schedule {
justify-content: space-between;
@media screen and (max-width: 768px) {
	justify-content: center
}
}


.recent {
	margin-top: 20px;
	margin-bottom: 20px;
	width: auto;
	max-width: 300px;
	padding: 2px;
	align-items: center;
	justify-content: center;
	


}

.recentDiv{

	width: max-content;
	max-width: 200px;
	text-align: center;
	@include transition(all 0.5s);
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: left;
	
	
  top: 50%;
  left: 50%;
	


	

}

.recentDiv:hover {
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0.3));
	top: -5px;
}
// Tags

.tags > a {
    text-decoration: none;
}
.tag {
	background: #b2e8fa;
	padding: 10px 15px;
	color: #222;
	@include transition(0.5s);
	margin-right: 8px;
	margin-top: 5px;
	margin-bottom: 5px;
	display: inline-block;
	@include border-radius(3px);
}
.tag:hover, .tag:focus {
	background: $yellow;
	color: #fff;
	text-decoration: none;
}

// Most popular

.most-popular-img {
    height: 200px;
	width: 100%;
	margin-bottom: 8px;
	margin-top:5px;
	object-fit: cover;
	
	
}

.most-popular-font {
   font-weight: 500; 
}

.most-popular-font-meta {
    font-size: 12px;
	color: #777;
}

// Trending
.trending-img-position {
	position: relative;
	overflow: hidden; /* This will hide anything that goes outside the container */
	z-index: 5;
	cursor: pointer;
}

/* Specific sizing for images */
.trending-img-size {
	height: 280px;
	width: auto; /* Maintain aspect ratio */
	overflow: hidden;
	border-radius: 3px;
	display: flex; /* To center the image inside */
	justify-content: center; /* Horizontal centering */
	align-items: center; /* Vertical centering */
}
.trending-img-size > img {
	max-height: 280px; /* Set a maximum height */
	width: auto; /* Let the width adjust according to the aspect ratio */
	max-width: 100%; /* Ensure it doesn't go beyond its container */
	object-fit: cover; /* This ensures the image covers the entire space without being squished */
}

/* For elements that require relative positioning */
.trending-img-relative {
	position: relative;
}

/* The overlay gradient on images */
.trending-img-absolute {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.83) 100%);
	z-index: 7;
}
.trending-img-absolute-1 {
	position: absolute;
	width: 100%;
	z-index: 9;
	padding: 10px;
	bottom: 0;
}

.trending-meta-info {
	color: rgba(255,255,255,0.7);
	font-size: 13px;
}

.trending-img-position:hover {
	-webkit-animation: swing1 1s ease;
	animation: swing1 1s ease;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
 @-webkit-keyframes swing1 {
 15% {
 -webkit-transform: translateX(5px);
 transform: translateX(5px);
}
 30% {
 -webkit-transform: translateX(-5px);
 transform: translateX(-5px);
}
 50% {
 -webkit-transform: translateX(3px);
 transform: translateX(3px);
}
 65% {
 -webkit-transform: translateX(-3px);
 transform: translateX(-3px);
}
 80% {
 -webkit-transform: translateX(2px);
 transform: translateX(2px);
}
 100% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
}
@keyframes swing1 {
 15% {
 -webkit-transform: translateX(5px);
 transform: translateX(5px);
}
 30% {
 -webkit-transform: translateX(-5px);
 transform: translateX(-5px);
}
 50% {
 -webkit-transform: translateX(3px);
 transform: translateX(3px);
}
 65% {
 -webkit-transform: translateX(-3px);
 transform: translateX(-3px);
}
 80% {
 -webkit-transform: translateX(2px);
 transform: translateX(2px);
}
 100% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
}

// search
.form-inline {
	display: flex;
	align-items: center;
	width: fit-content;
}

.search-btn {
	float: left;
	margin-left: 3px;
}

// Category
.widget {
	position: relative;
    display: block;
    margin-bottom: 3rem;
}
.widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.link-widget li {
    padding: 1.2rem 0.8rem 1.7rem;
    text-transform: capitalize;
    line-height: 1;
    font-weight: 500;
    position: relative;
    margin: 0;
    border-bottom: 1px dashed #dadada;
}

.link-widget li:last-child {
    border-bottom: 0 dashed #dadada;
}

.link-widget li span {
    position: absolute;
    right: 0;
}

// Scroll
.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	@include transition(.5s);
	cursor: pointer;
	z-index: 1;
	span {
		width: 50px;
		height: 50px;
		display: table;
		background: rgba(0,0,0,.5);
		color: white;
		text-align: center;
		@include border-radius(4px);
	}
	i {
		height: 20px;
		display: table-cell;
		vertical-align: middle;
	}
  }

//   Card
.related-content {
	border-radius: 20px;
	cursor: pointer;
  }

// Comment

.custombox {
	position: relative;
    padding: 3rem 2rem;
    border: 1px dashed #dadada;
	margin-top: 20px;
}

.comments-list p {
    margin-bottom: 1rem
}

.comments-list .media-right,
.comments-list small {
    color: #999 !important;
    font-size: 11px;
    letter-spacing: 2px;
    margin-top: 5px;
    padding-left: 10px;
    text-transform: uppercase;
}

.media {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
		-ms-flex-align: start;
			align-items: flex-start;
  }
  

.comments-list .media {
    padding: 1rem;
    margin-bottom: 15px;
}

.media-body {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 0%;
		-ms-flex: 1 1 0%;
			flex: 1 1 0%;
  }

.media-body .btn-primary {
    padding: 5px 15px !important;
    font-size: 11px !important;
}

.comments-list img {
    max-width: 80px;
    margin-right: 30px;
}

.media-left{
	margin-right: 20px;
	padding: 2px;



	
}
	 
.news-image {
	width: 100%;
	height: 200px;
	object-fit: cover; // This will ensure that your image covers the entire width and height of its box, changing the aspect ratio if needed.
  
	&:hover { // SCSS nesting allows you to specify &:hover within the class
	  opacity: 0.8;
	}
  }
  

.small-title {
    background: #edeff2 none repeat scroll 0 0;
    font-size: 16px;
	font-weight: 700;
    left: 5%;
    line-height: 1;
    margin: 0;
    padding: 0.6rem 1.5rem;
    position: absolute;
    text-align: center;
    top: -18px;
	color:#ffffff !important;
	background-color: #74a044 !important;
	border-color: #74a044 !important;
}

.user_name {
    font-size: 16px;
    font-weight: 600;
}

// Pagination
.btn_mange_pagging {
	background: #dadada;
	padding: 12px 20px;
	color: #222!important;
	font-weight: 800;
	margin: 0 10px;
	border-radius: 10px;
	@include transition(0.5s);
}

.btn_mange_pagging:hover, .btn_mange_pagging:focus {
	background: #222;
	color: #fff!important;
	font-weight: 800;
}

.btn_pagging {
	background: #dadada!important;
	color: #222;
	padding: 12px 20px!important;
	font-weight: 800;
	margin: 0 10px;
	@include transition(0.5s);
}

.btn_pagging:hover, .btn_pagging:focus {
	background: #222!important;
	color: #fff;
}

.scroll {
	width: auto;
	height: 230px;
	overflow-x: hidden;
	overflow-y: auto;
	text-align:justify;
}

//About
.li {
	

	margin-bottom: 20px;
	

}

//lights

.lights {
	
	margin-top: 2rem;
	flex-direction: column;
	width: 100%;
	
	padding: 1rem;
	border-radius: 1rem;
	background-color: rgb(228, 228, 228);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	transition: all 0.3s;
	

}

.lights li { 
	margin-bottom: 20px;
	background-color: #ffffff;
	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	

	

}

.lights h6{
	font-size: 0.7rem;
	font-weight: 700;
	margin-bottom: 1rem;	
	color: #000270;
	

}

.featblog {
	
	margin-top: 2rem;
	flex-direction: column;
	width: 100%;
	
	padding: 1rem;
	border-radius: 1rem;
	background-color: rgb(219, 213, 213);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	transition: all 0.3s;

	

	


	

	
}

.postsGo {
	border-radius: 5px;
}

//richtetx

.editor {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}


.blogCard {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	padding: 1rem;
	border-radius: 1rem;
	background-color: rgb(226, 226, 226);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	transition: all 0.3s;

}

.trendImg { 
height: 500px;
width: 415px;


}


.trendImg:hover {
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0.3));
	top: -5px;
}

.owl-carousel .prev-carousel:hover {
	background-position: 0px -53px;
  }
  
  .owl-carousel .next-carousel:hover {
	background-position: -24px -53px;
  }
  
  .owl-carousel .nav-button {
	height: 50px;
	width: 25px;
	cursor: pointer;
	position: absolute;
	top: 110px !important;
  }